<template>
  <div class="restore-questionnaire-page">
    <restore-questionnaire-modal
      :patient-email="email"
      :error="error"
      @continue="onContinue"
      @input="onInput"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import RestoreQuestionnaireModal from '@/modules/questionnaire/components/dialogs/RestoreQuestionnaireModal';

import { getDoctorBySlug } from '@/api';
import { getDecodedId } from '@/utils';
import { hasResponseErrors } from '@/utils/storeActionsUtils';

import rootTypes from '@/store/types';
import { types } from '@/modules/questionnaire/store/types';
import { QUESTIONNAIRE_STATUS } from '@/modules/questionnaire/api/constants';

export default {
  name: 'PageRestoreQuestionnaire',
  components: { RestoreQuestionnaireModal },
  props: {
    lang: {
      type: String,
      required: true
    },
    hash: {
      type: String,
      required: true
    },
    withSlug: {
      type: Boolean,
      required: true
    },
    doctorLang: {
      type: String,
      default: ''
    },
    doctorSlug: {
      type: String,
      default: ''
    },
    doctorIdHash: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      email: '',
      error: '',
      doctorId: null
    };
  },
  computed: {
    ...mapGetters({
      patient: types.getters.GET_PATIENT,
      questionnaire: types.getters.GET_QUESTIONNAIRE
    })
  },
  async created() {
    if (this.doctorIdHash) {
      [this.doctorId] = getDecodedId(this.doctorIdHash);
    }

    if (!this.lang) {
      return;
    }

    await this.setLocale(this.lang);
  },
  mounted() {
    this.$modal.show('restore-questionnaire-modal');
  },
  methods: {
    ...mapActions({
      setLocale: rootTypes.actions.SET_LOCALE,
      restoreQuestionnaireAccess: types.actions.RESTORE_QUESTIONNAIRE_ACCESS,
      fetchQuestionnaire: types.actions.FETCH_QUESTIONNAIRE
    }),
    setEmailMatchError() {
      this.error = this.$t('info.warning.emailDontMatch');
    },
    onInput(value) {
      this.email = value;
    },
    async getDoctorIdBasedOnSlug() {
      if (this.withSlug) {
        const { data } = await getDoctorBySlug(this.doctorSlug);

        if (!data) {
          return;
        }

        const { id: doctorIdWithSlug } = data;

        return doctorIdWithSlug;
      }

      return this.doctorId;
    },
    async onContinue() {
      if (!this.doctorSlug && !this.doctorId) {
        return;
      }

      const doctorId = await this.getDoctorIdBasedOnSlug();

      if (!doctorId) {
        this.setEmailMatchError();

        return;
      }

      const [questionnaireId] = getDecodedId(this.hash);

      const restoreAccessResponse = await this.restoreQuestionnaireAccess({
        doctorId,
        questionnaireId,
        email: this.email
      });

      const { id: patientId } = this.patient;

      if (hasResponseErrors(restoreAccessResponse)) {
        this.setEmailMatchError();

        return;
      }

      const questionnaireResponse = await this.fetchQuestionnaire({ patientId, questionnaireId });

      if (hasResponseErrors(questionnaireResponse)) {
        this.setEmailMatchError();

        return;
      }

      const isQuestionnaireCompleted = this.questionnaire.status === QUESTIONNAIRE_STATUS.COMPLETED;

      if (isQuestionnaireCompleted) {
        this.$router.replace({ name: 'PageNotFound' });

        return;
      }

      this.$router.replace({ name: 'Questions' });
    }
  },
  metaInfo: {
    title: 'Questionnaire',
    titleTemplate: 'skinXs - %s'
  }
};
</script>
