<template>
  <modal
    :max-width="modalMaxWidth"
    width="100%"
    height="auto"
    :classes="['modal-window']"
    :click-to-close="false"
    adaptive
    scrollable
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div class=" modal-container flex-column flex--center--center">
      <div class="questionnaire">
        <div class="questionnaire__title u-typography-helvetica u-text u-text--l">
          {{ $t(title) }}
        </div>

        <div class="content questionnaire__content">
          <div class="content__prepend-content u-typography-helvetica u-text u-text--xs">
            <slot name="prepend-content"></slot>
          </div>

          <div class="content__main-content u-typography-helvetica u-text u-text--s">
            <slot name="main-content"></slot>
          </div>
        </div>

        <div class="questionnaire__actions actions">
          <slot name="actions"> </slot>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { isMobileDevice } from '@/modules/dashboard/utils';

export default {
  name: 'QuestionnaireBaseModal',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    modalMaxWidth() {
      return isMobileDevice() ? 328 : 550;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/colors';
@import '~universkin-shared/src/assets/styles/scss/sizes';
@import '~universkin-shared/src/assets/styles/scss/typography-helvetica.scss';

.questionnaire {
  width: 280px;
  margin: 0 auto;
  padding: 24px 0;

  &__title {
    margin-bottom: 8px;
    font-weight: 700;
    color: $color-graphite;
    letter-spacing: 0.4px;
    text-align: center;
  }

  &__content {
    margin-bottom: 24px;
    color: $color-graphite;
    letter-spacing: 0.4px;
    text-align: center;
  }
}

@media (min-width: $desktop-start) {
  .questionnaire {
    width: 450px;
    padding: 50px 0;

    &__title {
      margin-bottom: 16px;
      font-weight: 400;
    }
  }
}
</style>
