const createValidators = $t => ({
  REQUIRED: value => !!value || $t('info.warning.fieldRequired'),
  EMAIL: value => value.includes('@') || $t('info.warning.invalidEmail')
});

/**
 * Adds a property `validators` which contains an object
 * with localized validators.
 */
export const validatable = {
  data() {
    const validators = createValidators(this.$t);

    return {
      validators
    };
  }
};
