<template>
  <questionnaire-base-modal
    :title="$t('emailVerificationPage.title')"
    name="restore-questionnaire-modal"
  >
    <template #prepend-content>
      <p class="restore-questionnaire-modal__description">
        {{ $t('label.enterEmailToContinue') }}
      </p>
    </template>

    <template #main-content>
      <u-input
        class="restore-questionnaire-modal__email-input"
        :value="patientEmail"
        type="email"
        label="Email"
        :validators="[validators.REQUIRED, validators.EMAIL]"
        @input="onInput"
      />

      <div
        v-if="error"
        class="restore-questionnaire-modal__error-message u-typography-helvetica u-text u-text--xs"
      >
        {{ error }}
      </div>
    </template>

    <template #actions>
      <u-button
        class="restore-questionnaire-modal__continue-button"
        kind="primary"
        @click="$emit('continue')"
        >{{ $t('action.continue') }}</u-button
      >
    </template>
  </questionnaire-base-modal>
</template>

<script>
import { UButton, UInput } from 'universkin-shared';

import QuestionnaireBaseModal from '@/modules/questionnaire/components/dialogs/QuestionnaireBaseModal';

import { validatable } from '@/mixins/validatable';

export default {
  name: 'RestoreQuestionnaireModal',
  components: { UButton, UInput, QuestionnaireBaseModal },
  mixins: [validatable],
  props: {
    patientEmail: {
      type: String,
      required: true
    },
    error: {
      type: String,
      default: ''
    }
  },
  methods: {
    onInput(fieldValue) {
      this.$emit('input', fieldValue);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/colors';
@import '~universkin-shared/src/assets/styles/scss/sizes';
@import '~universkin-shared/src/assets/styles/scss/typography-helvetica.scss';

.restore-questionnaire-modal {
  &__description {
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.4px;
    text-align: center;
  }

  &__error-message {
    margin-top: 15px;
    color: $color-error;
  }

  &__continue-button {
    margin: 0 auto;
  }
}
</style>
